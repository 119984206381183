<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">

      <h1 class="title is-2 is-spaced">
        Useful Links
      </h1>

      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item field is-grouped">


            <p class="control">
              <button class="button" :disabled="!currentRow || currentRow.order == 0" @click="moveUp()">Up</button>
            </p>

            <p class="control">
              <button class="button" :disabled="!currentRow || currentRow.order == links.length - 1"
                @click="moveDown()">Down</button>
            </p>

            <p class="control">
              <button class="button is-danger is-light" :disabled="!currentRow" @click="deleteRow()">Delete</button>
            </p>
          </div>
        </div>


        <div class="level-right">
          <div class="level-item field is-grouped">

            <p class="control">
              <button class="button" is-success is-light :disabled="blockSave" @click="save()">Save
                Changes</button>
            </p>
            <p class="control">
              <button class="button" is-info is-light :disabled="!thereAreChanges" @click="reset()">Reset
                Changes</button>
            </p>
            <p class="control">
              <button class="button is-primary is-light" :disabled="!okToAdd" @click="addNew()">Add New</button>
            </p>
          </div>
        </div>


      </nav>
      <ve-table :api-mode="false" :editOption="editOption" :columns="columns" :table-data="links"
        row-key-field-name="order" :fixed-header="true" :radio-option="radioOption" width="100%">
      </ve-table>






    </section>

  </div>
</template>

<script>

import isURL from 'validator/lib/isURL';
import { toast } from 'bulma-toast'

export default {
  name: "linksManager",

  components: {

  },

  data: function () {
    return {

      currentRow: null,
      added: false,
      deleted: false,
      original: {},

      radioOption: {
        selectedRowKey: "",

        selectedRowChange: ({ row }) => {
          console.log(row);
          this.currentRow = row
          this.radioOption.selectedRowKey = row.index
          return true
        },
      },

      editOption: {
        beforeCellValueChange: ({ _row, column, changeValue }) => {

          if (column.field != "link") {
            return
          }

          return isURL(changeValue)



        },
        afterCellValueChange: ({ row, column, changeValue }) => {

          if (typeof (changeValue) != "string") return

          console.log(row, column, changeValue)

          this.links[row.index][column.field] = changeValue.trim()
          this.changes *= -1
        }
      },
      links: [],

      columns: [
        {
          field: "index",
          key: "index",
          operationColumn: true,
          title: "",
          width: 50,
          align: "center",
          changes: -1,
          renderBodyCell: ({ row, _column, _rowIndex }, _h) => {
            return row.order + 1;
          },
          edit: true,

        },
        { field: "", key: "a", type: "radio" },
        { field: "name", key: "name", title: "Name", edit: true },
        { field: "notes", key: "notes", title: "Notes", edit: true, },
        { field: "link", key: "link", title: "Link", edit: true, },

        //          <button :disabled="rowIndex==0">Up</button>
        //        <button :disabled="rowIndex>=this.links.length-1">Up</button>
        //       </div> )




        // { field: "col4", key: "col4", title: "Col4" },
        // { field: "col5", key: "col5", title: "Col5" },
        // { field: "col6", key: "col6", title: "Col6" },
      ],
    };
  },

  computed: {

    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

    blockSave() {
      if (!this.thereAreChanges) {

        return true
      }

      return !this.okToAdd
    },

    thereAreChanges() {

      if (this.added || this.deleted) {
        return true
      }

      if (this.changes > 2) {
        return true
      }

      return this.links.filter(x => {
        for (let z of Object.entries(x.original)) {

          if (x[z[0]] != z[1]) {
            console.log("There is a change")
            return true
          }
        }
        return false;
      }).length > 0

    },
    okToAdd() {


      if (this.changes > 2) {
        return true
      }
      console.log("Can I add another one yet?")

      let rtn = (this.links.filter(x => {
        for (let z of Object.keys(x.original)) {
          if (x[z] === "") {
            console.log(z, "empty")
            return true
          }


        }
        return false;
      }

      ).length == 0)
      console.log("Returning:", rtn)
      return rtn
    }



  },
  methods: {

    addNew() {
      this.links.unshift({ name: "", notes: "", link: "", order: 0, original: { name: "", notes: "", link: "", order: -1 } })
      this.links = this.links.map((x, index) => {
        x.order = index
        x.index = index
        return x
      })

    },

    moveUp() {

      let y = this.currentRow.order

      if (y < 1) {
        return
      }

      this.links.splice(y - 1, 0, this.links.splice(y, 1)[0]);
      this.currentRow.order = y - 1
      this.links[y].order = y
      this.changes *= -1
    },

    moveDown() {

      let y = this.currentRow.order

      if (y >= this.links.length - 1) {
        return
      }

      this.links.splice(y + 1, 0, this.links.splice(y, 1)[0]);
      this.currentRow.order = y + 1
      this.links[y].order = y
      this.changes *= -1
    },

    reset() {
      this.currentRow = null
      this.changes *= -1
      this.radioOption.selectedRowKey = "";
      this.links = this.original.map((x, index) => {
        x.order = index
        x.index = index
        return x
      })

    },



    deleteRow() {

      let y = this.currentRow.order
      console.log({ index: y, links: this.links })
      this.links.splice(y, 1)
      this.currentRow = null
      this.changes *= -1
      this.radioOption.selectedRowKey = "";
      console.log({ index: y, links: this.links })


      this.links = this.links.map((x, index) => {
        x.order = index
        x.index = index
        return x

      })
      console.log({ index: y, links: this.links })

    },

    save() {


      let data = this.links.map(x => {
        return {
          link: x.link,
          name: x.name,
          notes: x.notes
        }
      })
      console.log(data)
      this.$api.post("v2/staff/links/manage/", true, true, data).then(x => {
        if (x.method !== "update") {
          toast({
            message: "Not sure what happened. Please try again",
            type: 'is-danger',
            duration: 5000,
            closeOnClick: true
          })
          return
        }

        this.currentRow = null
        this.changes *= -1
        this.links = data.map((x, index) => {
          x.order = index;
          x.index = index
          x.original = { ...x }
          return x
        })
        this.original = this.links.map(x => x)


        toast({
          message: "Changes have been successfully made",
          type: 'is-success',
          duration: 5000,
          closeOnClick: true
        })
      })
    }
  },

  async mounted() {

    this.$api.get("v2/staff/links/", true, true).then(resp => {
      this.links = resp.map((x, index) => {
        x.order = index;
        x.index = index
        x.original = { ...x }
        return x
      })
      this.original = this.links.map(x => x)


    })



  },
};
</script>


<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>